import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { logout, userSelector } from '../features/auth';
import { ArrowRightThickIcon } from '../icons';
import LogoSpedireNavbarBadgeSVG from '../images/logo-spedire-navbar-badge.svg';

export const Header: React.FC<{ className?: string; headerText?: string }> = ({ className = '', headerText }) => {
  const user = useSelector(userSelector);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const text = ''; //headerText || t('ticket.header.offered_by');

  return (
    <>
      <header
        className={classNames(`${className} w-full`, {
          'bg-gray-50': !className.includes('bg-')
        })}
      >
        <div className={classNames({ invisible: !user })}>
          <p className="p-1 text-xs text-right">
            {t('ticket.header.logged_as', { email: user?.email })}
            <a
              className="underline cursor-pointer"
              onClick={() => {
                dispatch(logout());
                location.reload();
              }}
            >
              {t('ticket.header.click_here_to_logout')}
            </a>
          </p>
        </div>
        <div className="flex flex-row items-center justify-center mt-1 md:mt-2 mb-3 md:mb-8">
          <a href="https://www.spedire.com/">
            <div className="h-9 px-4 inline-flex justify-center items-center bg-blue-500 hover:bg-blue-700 shadow text-white rounded-3xl text-sm leading-5 font-normal cursor-pointer">
              {text} <LogoSpedireNavbarBadgeSVG className="ml-2" />{' '}
              <ArrowRightThickIcon className="text-white w-3 ml-3" />
            </div>
          </a>
        </div>
      </header>
    </>
  );
};
