import React from 'react';
import { Footer } from './footer';
import { Header } from './header';

export const Layout: React.FC<{
  header?: React.ReactNode;
  hideFooter?: boolean;
  isMerchantFooter?: boolean;
  headerText?: string;
}> = ({ children, header, hideFooter, isMerchantFooter, headerText }) => (
  <div className="h-full flex flex-col">
    {header ? header : <Header headerText={headerText} />}
    <main className="flex-1">{children}</main>
    {hideFooter ? null : <Footer isMerchantFooter={isMerchantFooter} />}
  </div>
);
