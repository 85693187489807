import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import FacebookSVG from '../../images/icons/facebook.svg';
import InstagramSVG from '../../images/icons/instagram.svg';
import LinkedinSVG from '../../images/icons/linkedin.svg';
import TwitterSVG from '../../images/icons/twitter.svg';
import LogoSVG from '../../images/spedire_white_logo.svg';

const socialIcons = {
  twitter: TwitterSVG,
  instagram: InstagramSVG,
  linkedin: LinkedinSVG,
  facebook: FacebookSVG
};

const FooterLink: React.FC<{ href: string; name: string; small?: boolean }> = ({ href, name, small }) => {
  const commonStyle = `text-white hover:text-blue-200 underline lg:no-underline lg:hover:underline ${
    small ? 'text-right text-sm leading-5' : 'text-base'
  }`;
  return href.includes('https://') ? (
    <a href={href} className={commonStyle}>
      {name}
    </a>
  ) : (
    <Link to={href} className={commonStyle}>
      {name}
    </Link>
  );
};

export const Footer: React.FC<{ isMerchantFooter?: boolean }> = ({ isMerchantFooter }) => {
  const { footerJson } = useStaticQuery(graphql`
    query FooterQuery {
      footerJson {
        rates {
          href
          name
        }
        resources {
          href
          name
        }
        social {
          href
          icon
          name
        }
        whoWeAre {
          href
          name
        }
      }
    }
  `);

  if (isMerchantFooter) {
    return (
      <footer aria-labelledby="footerHeading" className="text-gray-400 bg-gray-50">
        <h2 id="footerHeading" className="sr-only">
          Footer
        </h2>
        <div className="max-w-3xl mx-auto pb-6 px-4 sm:px-6 lg:px-8">
          <div className="pt-4 flex justify-between">
            <div className="flex flex-col md:flex-row">
              {footerJson.whoWeAre.slice(-2).map((item) => (
                <span key={item.name} className="mr-6">
                  <a href={item.href} className="text-right text-sm leading-5 underline">
                    {item.name}
                  </a>
                </span>
              ))}
            </div>
            <p className="mt-3 md:mt-0 md:flex-1 md:text-right text-sm leading-5 font-normal">
              ESITE SRL - P.IVA IT02227730229
            </p>
          </div>
        </div>
      </footer>
    );
  }

  return (
    <footer className="bg-blue-500" aria-labelledby="footerHeading">
      <h2 id="footerHeading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8 xl:col-span-1">
            <LogoSVG className="h-10" />
            <div className="flex space-x-6 justify-center">
              {footerJson.social.map((item) => {
                const Icon = socialIcons[item.icon];
                return (
                  <a
                    key={item.name}
                    href={item.href}
                    className="text-white hover:text-white p-3 bg-blue-300 rounded-full"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="sr-only">{item.name}</span>
                    <Icon className="h-4 w-4" />
                  </a>
                );
              })}
            </div>
          </div>
          <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-white tracking-wider">Chi siamo?</h3>
                <ul className="mt-4 space-y-4">
                  {footerJson.whoWeAre.map((item) => (
                    <li key={item.name}>
                      <FooterLink {...item} />
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-white tracking-wider">Tariffe</h3>
                <ul className="mt-4 space-y-4">
                  {footerJson.rates.map((item) => (
                    <li key={item.name}>
                      <FooterLink {...item} />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-white tracking-wider">Risorse</h3>
                <ul className="mt-4 space-y-4">
                  {footerJson.resources.map((item) => (
                    <li key={item.name}>
                      <FooterLink {...item} />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-12 border-t border-gray-200 pt-8 flex justify-between">
          <p className="text-base text-white">ESITE SRL - P.IVA IT02227730229 - ITALIA 🇮🇹</p>
        </div>
      </div>
    </footer>
  );
};
